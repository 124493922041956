<template>
    <div class="overview-area ptb-100 with-top-border">
        <div class="container">
            <div class="overview-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12 overview-image style-one">
                        <img src="../../assets/images/overview/overview3.png" data-aos="fade-down" data-aos-duration="1200" alt="overview-image">
                        <img src="../../assets/images/overview/overview4.png" data-aos="fade-up" data-aos-duration="1200" alt="overview-image">
                    </div>
                    <div class="col-lg-5 col-md-12 overview-content">
                        <span class="sub-title">Send & Receive Payments</span>
                        <h2>Online payments for any business setup</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                Verification for 1 business day
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                No setup fee, no hidden fees
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                Transparent payouts details
                            </li>
                        </ul>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                            <router-link to="/contact" class="btn-style-one light-green-color">
                                Get Started Now 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overview-item">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12 overview-content">
                        <span class="sub-title">Minimal Dashboard</span>
                        <h2>The world’s most powerful & easy-to-use</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                Fastest way to integrate payments
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                Get instant notifications
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                Support assistance with integration
                            </li>
                        </ul>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                            <router-link to="/contact" class="btn-style-one light-green-color">
                                Get Started Now 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 overview-image style-two">
                        <img src="../../assets/images/overview/overview5.png" data-aos="fade-up" data-aos-duration="1200" alt="overview-image">
                        <img src="../../assets/images/overview/overview6.png" alt="overview-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReceivePayments'
}
</script>