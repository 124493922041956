<template>
    <div class="why-choose-us-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <h2 class="nunito-font">Benefícios?</h2>
                <p>Quais benefícios o Dark Voice vai me proporcionar?</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-why-choose-us-box">
                        <div class="icon">
                            <i class="flaticon-expand"></i>
                        </div>
                        <h3 class="nunito-font">
                            <router-link to="/services-details">Eficiência Aprimorada</router-link>
                        </h3>
                        <p>Agilize o processo de criação com gerador de áudio, tradução de notícias e roteiros automáticos, economizando tempo precioso.</p>
                        <!-- <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-why-choose-us-box active">
                        <div class="icon">
                            <i class="flaticon-return-on-investment"></i>
                        </div>
                        <h3 class="nunito-font">
                            <router-link to="/services-details">Gerenciamento Simplificado</router-link>
                        </h3>
                        <p>Organize canais com agendas personalizadas, acompanhando métricas-chave para melhor planejamento e crescimento sustentável.</p>
                        <!-- <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-why-choose-us-box">
                        <div class="icon">
                            <i class="flaticon-simple"></i>
                        </div>
                        <h3 class="nunito-font">
                            <router-link to="/services-details">SEO Otimizado</router-link>
                        </h3>
                        <p>Otimize a visibilidade de seus vídeos no YouTube com tags criadas automaticamente e traduzidas para diversos idiomas.</p>
                        <!-- <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-why-choose-us-box">
                        <div class="icon">
                            <i class="flaticon-bell"></i>
                        </div>
                        <h3 class="nunito-font">
                            <router-link to="/services-details">Acesso a Imagens Relevante</router-link>
                        </h3>
                        <p>Encontre imagens diretamente na ferramenta para enriquecer seu conteúdo visual, poupando busca externa e armazenamento local.</p>
                        <!-- <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-why-choose-us-box">
                        <div class="icon">
                            <i class="flaticon-maximize"></i>
                        </div>
                        <h3 class="nunito-font">
                            <router-link to="/services-details">Suporte Criativo Instantâneo</router-link>
                        </h3>
                        <p>Integração do ChatGPT para ideias, roteiros e respostas rápidas, estimulando a criatividade e a interatividade do conteúdo.</p>
                        <!-- <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-why-choose-us-box">
                        <div class="icon">
                            <i class="flaticon-security"></i>
                        </div>
                        <h3 class="nunito-font">
                            <router-link to="/services-details">Aumento do Lucro</router-link>
                        </h3>
                        <p>Com mais eficiência e qualidade, o Dark Voice potencializa a produtividade, impulsionando os ganhos em até 200%.</p>
                        <!-- <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>