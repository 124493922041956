<template>
    <div class="faq-area bg-f1f5fd">
        <div class="container">
            <div class="faq-accordion-content">
                <div class="box">
                    <h3>Getting Started</h3>
                    <div class="accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Do I have to be a student to take an online course?
                            </button>
                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p><strong>Vumy</strong> is always looking for talented <a href="#">information</a> security and IT risk management professionals who are dedicated, hard working and looking for a challenge. If you are interested in employment with <strong>Vumy</strong>, a company who values you and your family, visit our careers page.</p>
                                    <ul>
                                        <li>a console</li>
                                        <li>Two Joy-Con controllers that are detachable</li>
                                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How old do I have to be to take an online class?
                            </button>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                    <ul>
                                        <li>a console</li>
                                        <li>Two Joy-Con controllers that are <a href="#">detachable</a></li>
                                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                        <li>Two straps for turning the Joy-Cons into <strong>individual</strong> controllers</li>
                                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                What if I change my mind and want to drop my class?
                            </button>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.</p>
                                    <p>Tunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                What are the technical requirements for an online course?
                            </button>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <ul>
                                        <li>a console</li>
                                        <li>Two Joy-Con controllers that are detachable</li>
                                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                May I take a class as non-credit and pay a reduced tuition?
                            </button>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <h3>Account/Profile</h3>
                    <div class="accordion" id="faqAccordion2">
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                What do Vumy courses include?
                            </button>
                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p><strong>Vumy</strong> is always looking for talented <a href="#">information</a> security and IT risk management professionals who are dedicated, hard working and looking for a challenge. If you are interested in employment with <strong>Vumy</strong>, a company who values you and your family, visit our careers page.</p>
                                    <ul>
                                        <li>a console</li>
                                        <li>Two Joy-Con controllers that are detachable</li>
                                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                How do I take a Vumy course?
                            </button>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                    <ul>
                                        <li>a console</li>
                                        <li>Two Joy-Con controllers that are <a href="#">detachable</a></li>
                                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                        <li>Two straps for turning the Joy-Cons into <strong>individual</strong> controllers</li>
                                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                Is there any way to preview a course?
                            </button>
                            <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.</p>
                                    <p>Tunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                How can I pay for a course?
                            </button>
                            <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <ul>
                                        <li>a console</li>
                                        <li>Two Joy-Con controllers that are detachable</li>
                                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                Where can I go for help?
                            </button>
                            <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <h3>Purchase/Refunds</h3>
                    <div class="accordion" id="faqAccordion3">
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                                Where can I see the status of my refund?
                            </button>
                            <div id="collapseEleven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p><strong>Vumy</strong> is always looking for talented <a href="#">information</a> security and IT risk management professionals who are dedicated, hard working and looking for a challenge. If you are interested in employment with <strong>Vumy</strong>, a company who values you and your family, visit our careers page.</p>
                                    <ul>
                                        <li>a console</li>
                                        <li>Two Joy-Con controllers that are detachable</li>
                                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                When will I receive my refund?
                            </button>
                            <div id="collapseTwelve" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                    <ul>
                                        <li>a console</li>
                                        <li>Two Joy-Con controllers that are <a href="#">detachable</a></li>
                                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                        <li>Two straps for turning the Joy-Cons into <strong>individual</strong> controllers</li>
                                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                What is a “credit refund”?
                            </button>
                            <div id="collapseThirteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.</p>
                                    <p>Tunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                What is the difference between a refund and a purchase reversal?
                            </button>
                            <div id="collapseFourteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <ul>
                                        <li>a console</li>
                                        <li>Two Joy-Con controllers that are detachable</li>
                                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                How to Search for Courses on Udemy
                            </button>
                            <div id="collapseFifteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Faq'
}
</script>