import { createWebHistory, createRouter } from "vue-router";

import ITStartupPage from "../components/Pages/ITStartupPage";
import BusinessPage from "../components/Pages/BusinessPage";
import AppPage from "../components/Pages/AppPage";
import InsurancePage from "../components/Pages/InsurancePage";
import PropertyPage from "../components/Pages/PropertyPage";
import BigDataPage from "../components/Pages/BigDataPage";
import DistanceLearningPage from "../components/Pages/DistanceLearningPage";
import ChatBotPage from "../components/Pages/ChatBotPage";
import SoftwarePage from "../components/Pages/SoftwarePage";
import MedicalPage from "../components/Pages/MedicalPage";
import AboutOnePage from "../components/Pages/AboutOnePage";
import AboutTwoPage from "../components/Pages/AboutTwoPage";
import TeamPage from "../components/Pages/TeamPage";
import TestimonialsPage from "../components/Pages/TestimonialsPage";
import CoursesPage from "../components/Pages/CoursesPage";
import CoursesDetailsPage from "../components/Pages/CoursesDetailsPage";
import PricingPage from "../components/Pages/PricingPage";
import FeaturesPage from "../components/Pages/FeaturesPage";
import ServicesPage from "../components/Pages/ServicesPage";
import ServicesTwoPage from "../components/Pages/ServicesTwoPage";
import ServicesThreePage from "../components/Pages/ServicesThreePage";
import ServicesFourPage from "../components/Pages/ServicesFourPage";
import ServicesDetailsPage from "../components/Pages/ServicesDetailsPage";
import AuthenticationPage from "../components/Pages/AuthenticationPage";
import ForgotPasswordPage from "../components/Pages/ForgotPasswordPage";
import FaqPage from "../components/Pages/FaqPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsConditionsPage from "../components/Pages/TermsConditionsPage";
import ComingSoonPage from "../components/Pages/ComingSoonPage";
import ErrorPage from "../components/Pages/ErrorPage";
import ThankYouPage from "../components/Pages/ThankYouPage";
import PortfolioPage from "../components/Pages/PortfolioPage";
import PortfolioTwoPage from "../components/Pages/PortfolioTwoPage";
import PortfolioDetailsPage from "../components/Pages/PortfolioDetailsPage";
import ProductsPage from "../components/Pages/ProductsPage";
import CartPage from "../components/Pages/CartPage";
import CheckoutPage from "../components/Pages/CheckoutPage";
import ProductsDetailsPage from "../components/Pages/ProductsDetailsPage";
import BlogGridPage from "../components/Pages/BlogGridPage";
import BlogLeftSidebarPage from "../components/Pages/BlogLeftSidebarPage";
import BlogRightSidebarPage from "../components/Pages/BlogRightSidebarPage";
import BlogSpecialPage from "../components/Pages/BlogSpecialPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import BlogDetailsTwoPage from "../components/Pages/BlogDetailsTwoPage";
import BlogDetailsThreePage from "../components/Pages/BlogDetailsThreePage";
import BlogAuthorPage from "../components/Pages/BlogAuthorPage";
import BlogCategoryPage from "../components/Pages/BlogCategoryPage";
import BlogTagPage from "../components/Pages/BlogTagPage";
import BlogSearchPage from "../components/Pages/BlogSearchPage";
import ContactPage from "../components/Pages/ContactPage";

const routes = [
  { path: "/it", name: "ITStartupPage", component: ITStartupPage },
  { path: "/business", name: "BusinessPage", component: BusinessPage },
  { path: "/app", name: "AppPage", component: AppPage },
  { path: "/insurance", name: "InsurancePage", component: InsurancePage },
  { path: "/property", name: "PropertyPage", component: PropertyPage },
  { path: "/", name: "BigDataPage", component: BigDataPage },
  {
    path: "/distance-learning",
    name: "DistanceLearningPage",
    component: DistanceLearningPage,
  },
  { path: "/chat-bot", name: "ChatBotPage", component: ChatBotPage },
  { path: "/software", name: "SoftwarePage", component: SoftwarePage },
  { path: "/medical", name: "MedicalPage", component: MedicalPage },
  { path: "/about-us", name: "AboutOnePage", component: AboutOnePage },
  { path: "/about-us-two", name: "AboutTwoPage", component: AboutTwoPage },
  { path: "/team", name: "TeamPage", component: TeamPage },
  {
    path: "/testimonials",
    name: "TestimonialsPage",
    component: TestimonialsPage,
  },
  { path: "/courses", name: "CoursesPage", component: CoursesPage },
  {
    path: "/courses-details",
    name: "CoursesDetailsPage",
    component: CoursesDetailsPage,
  },
  { path: "/pricing", name: "PricingPage", component: PricingPage },
  { path: "/features", name: "FeaturesPage", component: FeaturesPage },
  { path: "/services", name: "ServicesPage", component: ServicesPage },
  {
    path: "/services-two",
    name: "ServicesTwoPage",
    component: ServicesTwoPage,
  },
  {
    path: "/services-three",
    name: "ServicesThreePage",
    component: ServicesThreePage,
  },
  {
    path: "/services-four",
    name: "ServicesFourPage",
    component: ServicesFourPage,
  },
  {
    path: "/services-details",
    name: "ServicesDetailsPage",
    component: ServicesDetailsPage,
  },
  {
    path: "/profile-authentication",
    name: "AuthenticationPage",
    component: AuthenticationPage,
  },
  {
    path: "/lost-password",
    name: "ForgotPasswordPage",
    component: ForgotPasswordPage,
  },
  { path: "/faq", name: "FaqPage", component: FaqPage },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },
  { path: "/coming-soon", name: "ComingSoonPage", component: ComingSoonPage },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/thank-you", name: "ThankYouPage", component: ThankYouPage },
  { path: "/portfolio", name: "PortfolioPage", component: PortfolioPage },
  {
    path: "/portfolio-two",
    name: "PortfolioTwoPage",
    component: PortfolioTwoPage,
  },
  {
    path: "/portfolio-details",
    name: "PortfolioDetailsPage",
    component: PortfolioDetailsPage,
  },
  { path: "/products", name: "ProductsPage", component: ProductsPage },
  { path: "/cart", name: "CartPage", component: CartPage },
  { path: "/checkout", name: "CheckoutPage", component: CheckoutPage },
  {
    path: "/products-details",
    name: "ProductsDetailsPage",
    component: ProductsDetailsPage,
  },
  { path: "/blog-grid", name: "BlogGridPage", component: BlogGridPage },
  {
    path: "/blog-left-sidebar",
    name: "BlogLeftSidebarPage",
    component: BlogLeftSidebarPage,
  },
  {
    path: "/blog-right-sidebar",
    name: "BlogRightSidebarPage",
    component: BlogRightSidebarPage,
  },
  {
    path: "/blog-special",
    name: "BlogSpecialPage",
    component: BlogSpecialPage,
  },
  {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage,
  },
  {
    path: "/blog-details-two",
    name: "BlogDetailsTwoPage",
    component: BlogDetailsTwoPage,
  },
  {
    path: "/blog-details-three",
    name: "BlogDetailsThreePage",
    component: BlogDetailsThreePage,
  },
  { path: "/blog-author", name: "BlogAuthorPage", component: BlogAuthorPage },
  {
    path: "/blog-categories",
    name: "BlogCategoryPage",
    component: BlogCategoryPage,
  },
  { path: "/blog-tag", name: "BlogTagPage", component: BlogTagPage },
  {
    path: "/blog-search-result",
    name: "BlogSearchPage",
    component: BlogSearchPage,
  },
  { path: "/contact", name: "ContactPage", component: ContactPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
