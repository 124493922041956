<template>
    <div class="features-area bg-f3feff pt-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title light-green-color">Key Features</span>
                <h2>Most probably included best features ever</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-fast-time"></i>
                        </div>
                        <h3>Fast Start</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-features-box active">
                        <div class="icon">
                            <i class="flaticon-credit-card"></i>
                        </div>
                        <h3>Secure Payments</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-money-exchange"></i>
                        </div>
                        <h3>Multicurrency Account</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-updated"></i>
                        </div>
                        <h3>Regular Updates</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-brain"></i>
                        </div>
                        <h3>Intelligent Optimizations</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-secure-shield"></i>
                        </div>
                        <h3>Fully Secured</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>