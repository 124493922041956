<template>
    <div class="testimonials-area o-hidden ptb-100">
        <div class="container">
            <div class="section-title" data-aos="fade-up" data-aos-duration="1200">
                <h2 class="nunito-font">Veja o que quem ja é assinante diz sobre nós</h2>
                <p> </p>
            </div>
            <div class="testimonials-slides-three">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-testimonials-box">
                            <i class="flaticon-left-quote"></i>
                            <h5>{{slide.title}}</h5>
                            <p>{{slide.desc}}</p>
                            <div class="info">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                        </div>
                    </slide>
                    
                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                title: '“Do Caos à Excelência com o Dark Voice!”',
                desc: 'Eu estava lutando para encontrar uma maneira eficaz de criar vídeos de forma consistente, até que descobri o Dark Voice. Agora, com suas agendas personalizadas, tradução de notícias e sugestões do ChatGPT, meu processo de criação se tornou muito mais suave. Minha comunidade notou a diferença e estou adorando os resultados positivos. O Dark Voice é um verdadeiro game changer!',
                name: 'Lora Joly',
                position: 'Youtuber',
            },
            {
                id: 2,
                title: '“A Revolução na Criação de Conteúdo!”',
                desc: 'O Dark Voice transformou minha maneira de criar conteúdo! Com sua incrível integração de ferramentas e o suporte do ChatGPT, agora eu posso produzir vídeos de alta qualidade de forma mais rápida e eficiente. Minha produtividade aumentou consideravelmente, e os resultados falam por si. Recomendo fortemente a todos os criadores de conteúdo!',
                name: 'Silas',
                position: 'Youtuber',
            },
            {
                id: 3,
                title: '“Turbinei minha Produtividade com o Dark Voice!”',
                desc: 'Como youtuber, sempre estou em busca de soluções que facilitem meu trabalho. O Dark Voice é simplesmente revolucionário! A capacidade de gerenciar meus canais, otimizar SEO e até mesmo obter roteiros automáticos é impressionante. Isso não apenas economiza meu tempo, mas também eleva a qualidade do meu conteúdo. Uma ferramenta essencial para qualquer criador sério.',
                name: 'Rafael Leme Silva',
                position: 'Youtuber',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
        },
    }),
})
</script>