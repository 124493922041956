<template>
    <div class="help-desk-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">How Can Help You</span>
                <h2>We’re helping teams do their best work</h2>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon1.png" alt="icon">
                        </div>
                        <h3>Zero Configuration</h3>
                        <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon2.png" alt="icon">
                        </div>
                        <h3>Code Security</h3>
                        <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon3.png" alt="icon">
                        </div>
                        <h3>Team Management</h3>
                        <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon4.png" alt="icon">
                        </div>
                        <h3>Access Controlled</h3>
                        <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">
                            Learn More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelpDesk'
}
</script>