<template>
    <div class="faq-area bg-f9f9f9 pt-100">
        <div class="container">
            <div class="section-title">
                <h2 class="nunito-font">Precisa de ajuda com algo?</h2>
                <p>É só entrar em contato conosco por este fomulário.</p>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="faq-accordion">
                        <div class="accordion" id="faqAccordion">
                            <div class="accordion-item" data-aos="fade-up" data-aos-duration="1200" >
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    O que é o Dark Voice e como ele pode me ajudar no mercado digital?
                                </button>
                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body">
                                        <p>O Dark Voice é um sistema web projetado para otimizar a criação de conteúdo no mercado digital. Ele oferece recursos como gerador de áudio, gerenciamento de canais, SEO, tradução de notícias e até mesmo uma ferramenta de criação de roteiros. Com essa variedade de recursos, o Dark Voice torna mais fácil e eficiente produzir conteúdo de alta qualidade.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-controls="collapseTwo">
                                    Como posso começar a usar o Dark Voice? É fácil de configurar?
                                </button>
                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body">
                                        <p>Começar com o Dark Voice é simples. Basta se inscrever na plataforma e seguir as instruções para configurar sua conta. A interface intuitiva e os tutoriais disponíveis guiarão você pelas principais funcionalidades. Além disso, nossa equipe de suporte está pronta para ajudar caso você tenha alguma dúvida ou precise de assistência durante a configuração.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="accordion-item" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Como o Dark Voice ajuda a melhorar minha produtividade como criador de conteúdo?
                                </button>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body">
                                        <p>O Dark Voice melhora sua produtividade de várias maneiras. Ele oferece ferramentas para agendar postagens, gerenciar canais com métricas importantes, gerar tags SEO otimizadas e até mesmo criar roteiros automaticamente. Com esses recursos integrados, você pode realizar tarefas de maneira mais rápida e organizada, economizando tempo valioso.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    O Dark Voice é adequado para iniciantes ou apenas para criadores de conteúdo experientes?
                                </button>
                                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body">
                                        <p>O Dark Voice é projetado para atender a uma ampla gama de usuários, desde iniciantes até criadores de conteúdo experientes. Sua interface intuitiva e recursos automatizados tornam mais fácil para iniciantes começarem a criar conteúdo de qualidade. Para criadores experientes, as ferramentas avançadas, como a tradução de notícias e a integração do ChatGPT, oferecem suporte a projetos mais complexos.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Como a integração do ChatGPT no Dark Voice beneficia os criadores de conteúdo?  
                                </button>
                                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body">
                                        <p>A integração do ChatGPT no Dark Voice proporciona aos criadores de conteúdo um suporte instantâneo e criativo. Os usuários podem usar o ChatGPT para obter ideias, sugestões de roteiros e respostas rápidas. Isso impulsiona a criatividade e a interatividade do conteúdo, além de oferecer assistência valiosa em momentos de bloqueio criativo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ask-question-form">
                        <h3 class="nunito-font">Pode perguntar</h3>
                        <form @submit.prevent>
                            <div class="form-group">
                                <input type="text" placeholder="Digite seu nome completo">
                            </div>
                            <div class="form-group">
                                <input type="text" placeholder="Digite seu e-mail">
                            </div>
                            <div class="form-group">
                                <textarea cols="30" rows="5" placeholder="Sua mensagem"></textarea>
                            </div>
                            <button type="submit" class="btn-style-one orange-color">Enviar <i class="ph-caret-right"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Faq',
}
</script>