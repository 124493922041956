<template>
    <div class="features-area pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="features-item">
                        <div class="icon">
                            <i class="flaticon-big-data"></i>
                        </div>
                        <h3 class="nunito-font">Gerador de Áudio</h3>
                        <p>O Dark Voice oferece a opção de criar áudios utilizando vozes da Azure. Isso pode ser muito útil para adicionar narrações ou elementos de áudio aos vídeos, economizando tempo e esforço.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="features-item">
                        <div class="icon">
                            <i class="flaticon-data-management"></i>
                        </div>
                        <h3 class="nunito-font">Gerenciador de Canais</h3>
                        <p>A plataforma possibilita gerenciar canais de forma eficaz, criando agendas e acompanhando métricas, para uma visão completa do desempenho e melhor planejamento futuro.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="features-item">
                        <div class="icon">
                            <i class="flaticon-monitor"></i>
                        </div>
                        <h3 class="nunito-font">SEO para Vídeos</h3>
                        <p>A função SEO da ferramenta é valiosa, gerando tags otimizadas para vídeos no YouTube e simplificando a escolha de palavras-chave relevantes, com a vantagem extra de tradução para diversos idiomas.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>