<template>
    <div class="big-data-banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="big-data-banner-content">
                        <!-- <span class="sub-title" data-aos="fade-up" data-aos-duration="1200">#Get your 7 days free trial</span> -->
                        <h1 class="nunito-font" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">Facilitando a vida dos criadores de conteúdo</h1>
                        <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">Produza muito mais conteúdo no menor tempo possível e fature muito mais que a concorrência.</p>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                            <a href="https://pay.hotmart.com/N77864022E?off=iybscog9&checkoutMode=6&bid=1696024768367" class="btn-style-one orange-color" target="_blank">
                                Comece AGORA 
                                <i class="ph-caret-right"></i>
                            </a>
                            <!-- <router-link to="/about-us" class="btn-style-one white-color">
                                Quero saber mais 
                                <i class="ph-caret-right"></i>
                            </router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="big-data-banner-image" data-aos="fade-up" data-aos-duration="1200">
                        <!-- <img src="../../assets/images/banner/banner6.png" alt="banner-image"> -->
                        <!-- <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/TQ_tEolP3J8"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>