<template>
    <div class="working-process-area ptb-100 bg-542a88">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-img" data-aos="fade-up" data-aos-duration="1200">
                        <img src="../../assets/images/working-process2.png" alt="working-process">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-text">
                        <span class="sub-title">Tempo é dinheiro</span>
                        <h2 class="nunito-font">Lucre muito mais</h2>
                        <ul class="working-process-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <div class="number">
                                    1
                                </div>
                                <h3>Convergência</h3>
                                <p>A combinação de gerador de áudio, gerenciamento de canais, SEO otimizado e outras ferramentas em uma única plataforma oferece uma solução abrangente. Isso proporciona aos criadores de conteúdo uma experiência completa e simplificada, economizando tempo e esforço ao realizar várias tarefas.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="number">
                                    2
                                </div>
                                <h3>Inovação</h3>
                                <p>A integração do ChatGPT e a futura ferramenta de criação de roteiros destacam a abordagem inovadora do Dark Voice. Ao fornecer assistência criativa e funcionalidades de automação, a ferramenta supera soluções convencionais, atendendo às demandas crescentes de criação de conteúdo eficaz.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div class="number">
                                    3
                                </div>
                                <h3>Métricas</h3>
                                <p>A capacidade de acompanhar métricas, visualizar o desempenho do canal e planejar com eficiência oferece uma vantagem competitiva significativa. Os criadores de conteúdo podem tomar decisões informadas, adaptar suas estratégias e melhorar continuamente, resultando em crescimento real e lucrativo.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="shape10"><img src="../../assets/images/shape/shape10.png" alt="shape"></div> -->
        <!-- <div class="shape11"><img src="../../assets/images/shape/shape11.png" alt="shape"></div> -->
    </div>
</template>

<script>
export default {
    name: 'WorkingProcess'
}
</script>