<template>
    <div class="overview-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="section-title">
                <h2 class="nunito-font">IA e ChatGPT</h2>
                <p>Tenha insigths e gere suas idéias com auxilio do ChatGPT sem pagar nada a mais por isso.</p>
            </div>
            <div class="overview-item-style-two">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-image" data-aos="fade-up" data-aos-duration="1200">
                        <img src="../../assets/images/overview/overview7.png" alt="overview-image">
                    </div>
                    <div class="col-lg-6 col-md-12 overview-content">
                        <h2 class="nunito-font">Cansado de ter que pensar em tudo?</h2>
                        <p>Deixe-nos ajudar com a carga de trabalho cansativa que você criador de conteúdo tem que enfrentar.</p>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Obter Ideias Criativas</h3>
                                <p> Você criador de conteúdo pode usar o ChatGPT ilimitado para obter sugestões criativas para seus vídeos, posts ou outros tipos de conteúdo. Isso é especialmente útil quando estão enfrentando bloqueios criativos ou procuram uma perspectiva fresca.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Elaborar Roteiros</h3>
                                <p>O Dark Voice pode ajudar a desenvolver roteiros detalhados para vídeos ou outros projetos. Os criadores podem discutir suas ideias com o modelo e receber orientações sobre como estruturar o conteúdo.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Responder Perguntas</h3>
                                <p>Caso os criadores tenham dúvidas específicas sobre tópicos, formatos ou estratégias de conteúdo, o Dark Voice pode fornecer respostas e insights úteis.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Otimizar Conteúdo</h3>
                                <p>Os usuários podem pedir ao Dark Voice para revisar e aprimorar seus textos, garantindo que estejam bem escritos, envolventes e otimizados para os objetivos pretendidos.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="overview-item-style-two">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-content">
                        <h2 class="nunito-font">Risk Management</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Customer Churn Prediction</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Psychological Scoring</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Fraud Detection</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Preventive Maintenance</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-12 overview-image" data-aos="fade-up" data-aos-duration="1200">
                        <img src="../../assets/images/overview/overview8.png" alt="overview-image">
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataValue'
}
</script>